#navigator .team
{
  width: 100%;
  color: #ffffff;
}

#navigator .team::before
{
  border-color: #ffffff;
}

#navigator .team .MuiSvgIcon-root
{
  color: #ffffff;
}

#navigator .logo
{
  width: 48px;
  height: auto;
  margin-right: 16px;

  &.is-team-fetching {
    opacity: .5;
  }
}

#navigator .MuiSelect-select {
  width: 110px;
}
