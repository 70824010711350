.billing
{
  .billing-container
  {
    display: flex;
    margin: 1em -4px;
    flex-wrap: nowrap;
    align-items: center;
    border-bottom: 1px solid #dedede;

    .item
    {
      width: 33.3%;
      box-sizing: border-box;
      padding: 8px;
      margin: 0 4px;
    }

    .title
    {
      margin: 0;
      padding: 0;
      color: #555555;
    }

    p, .title
    {
      text-align: center;
    }

    .value
    {
      font-size: 1.5em;
      color: rgba(0, 149, 221, 1);
      margin: 0;
      padding: 0;
    }

    .billed-on
    {
      color: #555555;
      font-size: 0.9rem;
      margin: 0;
      padding: 0;
    }

    .empty
    {
      background-color: transparent;
      border: none;
    }

    .amount
    {
      font-size: 200%;
      font-weight: bolder;
    }

    .module-title
    {
      padding-left: 16px !important;
    }

    &.no-border
    {
      border: none;
    }
  }

  .usage-info
  {
    margin-bottom: 10px;
    .usage-info-title
    {
      font-weight: 600;
      color: #555555;
      padding: 0 15px;
    }
    .usage-card
    {
      padding: 30px 24px;
      height: 100%;
      text-align: center;
      position: relative;
      h3
      {
        font-size: 14px;
        color: #586069;
      }
      .usage-card-content
      {
        font-size: 24px;
        font-weight: 600;
        &.is-restricted {
          color: red;
        }

        small {
          font-size: 18px;
          font-weight: normal;
        }
      }
      .helper-text
      {
        font-size: 14px;
        margin: 0 1px;
      }
      .updated-at
      {
        font-size: 11px;
        text-align: right;
        color: #646567bf;
        position: absolute;
        bottom: 3px;
        right: 7px;
      }
      .restricted-mode-description {
        text-align: left;
      }
    }
  }

  .usage-details-info
  {
    margin-bottom: 20px;
    .MuiFormControl-root
    {
      margin: 15px 5px;
      #select-usage-api-key
      {
        padding-left: 8px;
        width: 290px;
      }
    }
    #chart-usage-api-key
    {
      max-width: 100%;
    }
    .chart-helper-text
    {
      text-align: right;
    }
  }

  .payment-info
  {
    margin-bottom: 20px;
    th
    {
      font-weight: bolder;
      color: #555555;
      max-width: 200px;
      padding: 0;
    }

    .MuiTableCell-body
    {
      padding: 8px;
    }
  }

  @media (max-width: 960px) {
    .payment-info
    {
      th
      {
        max-width: none;
      }
      tbody
      {
        tr
        {
          display: flex;
          flex-direction: column;
          padding: 16px 0;
          &:nth-child(1)
          {
            border-bottom: 1px solid rgba(224, 224, 224, 1);
          }
          th, td
          {
            border-bottom: none;
          }
        }
      }
    }
  }

  .module-title
  {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.downloadFormatMenu{
  .MuiPopover-paper{
    margin-top: 45px;
    box-shadow: none !important;
    padding: 10px !important;
    font-size: 14px;
    border-radius: inherit !important;
  }
}
