.gis-panel
{
  .editor
  {
    position: relative;
    margin: 0;
    height: 500px;
  }

  .editor.full-screen
  {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }

  .number-features
  {
    text-align: right;
    margin: 0;
  }

  .nav
  {
    text-align: right;
    position: relative;

    select
    {
      position: absolute;
      left: 0;
    }
    .btn
    {
      background-color: transparent;
      border: none;
      display: inline-flex;
      vertical-align: middle;
      line-height: 20px;
      cursor: pointer;
    }

    .label
    {
      font-size: 12px;
      margin-left: 0.5em;
    }
  }

  .geojson-meta
  {
    .is-public
    {
      display: inline-flex;
      vertical-align: middle;
      line-height: 24px;
      font-size: 14px;

      svg
      {
        margin-right: 4px;
      }
    }

    h3
    {
      margin-top: 0;
    }

    .geolonia-geojson-api-endpoint
    {
      width: 100%;
      padding: 4px;
    }

    .copy-button
    {
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      color: #009be5;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .geojson-meta
  {
    margin-top: 16px;

    .geojson-title-description:not(:first-child) {
      margin-top: 16px;
    }

    .geojson-title-description
    {
      h3
      {
        margin: 16px 0;
      }

      h3:first-of-type
      {
        margin-top: 0;
      }

      input[type="text"]
      {
        font-size: 16px;
        width: 100%;
        border: none;
        border-bottom: 1px solid #cccccc;
      }

      button
      {
        width: 100%;
      }
    }
    .MuiAccordion-root:before
    {
      content: none;
    }
    .geojson-advanced-settings
    {
      h3
      {
        margin: 0;
      }
    }
  }

  .props
  {
    position: absolute;
    top: 8px;
    left: 9px;
    width: 300px;
    height: 450px;
    padding: 16px;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #cccccc;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

    h3
    {
      margin: 16px 0 8px 0;
      font-size: 100%;
    }

    h3:nth-of-type(1)
    {
      margin-top: -10px;
    }

    input
    {
      width: 100%;
      font-size: 16px;
      padding: 4px;
      border: none;
      border-bottom: 1px solid #cccccc;
    }

    textarea
    {
      width: 100%;
      height: 3em;
      resize: none;
      border: none;
      border-bottom: 1px solid #cccccc;
      padding: 4px;
    }

    table
    {
      width: 100%;
    }

    th, td
    {
      padding: 4px;
    }

    th
    {
      text-align: left;
      min-width: 40%;
      font-size: 90%;
      font-weight: normal;
    }

    .select-menu
    {
      width: 100%;
    }

    .color-picker-container
    {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      z-index: 999999;
    }

    .backto,
    .goto-info
    {
      text-align: right;

      button
      {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .backto
    {
      text-align: left;
      margin-bottom: 16px;
    }

    .props-table
    {
      max-height: calc(100% - 40px);
      overflow: scroll;

      table
      {
        border-collapse: collapse;
        max-height: 100%;
      }

      tr:nth-child(even)
      {
        background-color: #f5f5f5;
      }

      tr
      {
        border-bottom: 1px solid #eeeeee;
      }

      thead
      {
        font-size: 90%;

        tr
        {
          background-color: #dedede;
        }

        th
        {
          text-align: center;
          font-weight: normal;
        }
      }

      tbody
      {
        th, td
        {
          padding: 4px 8px;
          min-width: 30%;
        }
      }

      input
      {
        padding: 0;
        background-color: transparent;
        border: none;
        margin: 0;
        font-size: 90%;
        width: 100%;
      }
    }
  }
}

.snackbar-update-required .MuiSnackbarContent-root {
  background: #ff9800;
  color: white;
}
