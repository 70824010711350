@import "./variables.scss";

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.module-title {
  margin-top: 1em;
  padding: 0 0 8px 0;
  font-weight: bolder !important;
  vertical-align: top;
  border-bottom: 3px solid #dedede;
  color: #555555;
  font-size: 1.5em !important;

  .MuiSvgIcon-root {
    position: relative;
    top: 6px;
  }
}

.private-or-public {
  font-size: 10px;
  margin: 0 1em;
  padding: 3px;
  border: 1px solid #dedede;
  border-radius: 3px;
}

p.description {
  margin: 1em 0;
  color: #555555;
}

.snackbar-saved.success .MuiSnackbarContent-root {
  color: #fff;
  background-color: #e8af2b;
}

.snackbar-saved.failure .MuiSnackbarContent-root {
  color: #000;
  background-color: hsl(19, 100%, 45%);
}

.MuiPaper-rounded {
  border-radius: 3px !important;
  box-shadow: none !important;
  border: 1px solid #dedede !important;
  box-shadow: 0 0 5px #eeeeee !important;
}

.MuiDialog-paper.MuiPaper-rounded {
  box-shadow: none !important;
}

p.mute {
  font-size: 12px;
  color: #777777;
  margin-top: 8px;
}
