.signup {
  width: 100%;
  text-align: center;
  color: #555555;

  .container {
    width: 320px;
    margin: 32px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .main {
      text-align: left;
    }

    .form {
      background-color: #ffffff;
      border: 1px solid #dedede;
      border-radius: 5px;
      padding: 24px 32px;

      label {
        padding: 0;
        display: block;
      }

      button {
        margin-top: 1em;
      }
    }

    .logo {
      width: 48px;
    }

    h1 {
      font-weight: normal;
      font-size: 24px;
      margin: 0.5em 0;
    }

    h2 {
      margin: 0 0 1em 0;
      font-weight: normal;
      font-size: 16px;
    }

    h3 {
      font-weight: normal;
      font-size: 14px;
      margin: 0.5em;
    }

    input,
    button {
      width: 100%;
    }

    input {
      padding: 8px;
      font-size: 14px;
    }

    .message {
      font-size: 12px;
      margin: 0.5em;
    }

    p {
      margin-bottom: 0;
    }

    .support-container {
      margin-top: 2em;
    }
  }
}
