.support-menu
{
  ul
  {
    margin: 0;
    padding: 0;
  }

  li
  {
    display: inline;
    margin: 0 1em;
    padding: 0;
    font-size: 12px;
  }
}
