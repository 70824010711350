@import '../variables.scss';

header{
  &.MuiPaper-root{
    background-color: #fff !important;
    padding: 7px !important;
  }
  .iconButtonAvatar{
    border: 1px solid $color-primary;
    margin-right: 10px;
    svg{
      fill: $color-primary;
    }
  }
  .iconButtonlogo{
    width: 40px;
    .logo{
      width: 100%;
    }
  }
  .MuiSvgIcon-root{
    fill: $color-primary;
  }
}

.headerProfilemenu{
  .MuiPopover-paper{
    top: 62px !important;
    box-shadow: none !important;
    padding: 10px !important;
    font-size: 14px;
    border-radius: inherit !important;
  }
}
