table.geolonia-list-table
{
  border-top: 3px solid #dedede;
  margin-top: 24px;

  tbody
  {
    tr
    {
      cursor: pointer;
    }

    tr.mouseover
    {
      background-color: #f5f5f5;
    }
  }
}
