@import './variables.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root
{
  padding: 16px;
}

a {color: $color-primary;}
.MuiTypography-colorPrimary{
  color: $color-primary !important;
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #E8AF2B !important;
}
