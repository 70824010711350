#dashboard
{
  .app-title
  {
    font-size: 200%;
    font-weight: bolder;
    margin: 0 0 1em 0;
    padding: 0;
  }

  .chart-container
  {
    width: 90%;
    height: 200px;
    margin: 2em auto 0 auto;
  }

  .container-map-loads,
  .container-geo-api-loads
  {
    color: #ffffff;

    .module-title
    {
      color: #ffffff;
    }
  }

  .container-map-loads
  {
    background-color: rgba(0, 149, 221, 1);
    border: none !important;
    box-shadow: none !important;
  }

  .container-geo-api-loads
  {
    background-color: rgba(255, 99, 132, 1);
    border: none !important;
    box-shadow: none !important;
  }

  .getting-started
  {
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 140%;
    width: 100%;
    background-image: url("custom/dashboardbg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    color: #333333;
    margin-bottom: 1em;
    border: none !important;
    box-shadow: none !important;
    padding: 35px;
    border: 6px solid #fff !important;

    .box-icon
    {
      width: 200px;
      max-width: 25%;
      padding-right: 24px;
      box-sizing: border-box;

      img
      {
        width: 100%;
        height: auto;
      }
    }

    .box-content
    {
      h2
      {
        margin: 10px 0;
        font-weight: 900;
        letter-spacing: 0.04em;
        color: #333333;
        line-height: 32px;
        font-size: 30px;
      }

      ul
      {
        margin: 1em 0 1em 0;
        padding: 0;

        li
        {
          margin-left: 1em;
          font-size: 14px;
          margin-bottom: 8px;
          letter-spacing: 0.06em;
        }
      }
    }
  }
  .create-new
  {
    background-color: #039A4A;
    color: #fff;
  }
}
